import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _a81b68b2 = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _bdddbae8 = () => interopDefault(import('../pages/fee.vue' /* webpackChunkName: "pages/fee" */))
const _7ec5f768 = () => interopDefault(import('../pages/package.vue' /* webpackChunkName: "pages/package" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _b8e80066 = () => interopDefault(import('../pages/tether-chart.vue' /* webpackChunkName: "pages/tether-chart" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _35112aa6 = () => interopDefault(import('../pages/callback/buy.vue' /* webpackChunkName: "pages/callback/buy" */))
const _a326cbb4 = () => interopDefault(import('../pages/callback/payment.vue' /* webpackChunkName: "pages/callback/payment" */))
const _1c83253c = () => interopDefault(import('../pages/callback/sell.vue' /* webpackChunkName: "pages/callback/sell" */))
const _7622b122 = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _1c619ad3 = () => interopDefault(import('../pages/dashboard/crypto-wallets.vue' /* webpackChunkName: "pages/dashboard/crypto-wallets" */))
const _ebe5b0a6 = () => interopDefault(import('../pages/dashboard/orders/index.vue' /* webpackChunkName: "pages/dashboard/orders/index" */))
const _40e7a1ee = () => interopDefault(import('../pages/dashboard/payment-result.vue' /* webpackChunkName: "pages/dashboard/payment-result" */))
const _7e08c7b4 = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _78c28e22 = () => interopDefault(import('../pages/dashboard/referral.vue' /* webpackChunkName: "pages/dashboard/referral" */))
const _2cea0045 = () => interopDefault(import('../pages/dashboard/security.vue' /* webpackChunkName: "pages/dashboard/security" */))
const _3be78fbe = () => interopDefault(import('../pages/dashboard/wallet.vue' /* webpackChunkName: "pages/dashboard/wallet" */))
const _a18ae4cc = () => interopDefault(import('../pages/pre-factor/buy.vue' /* webpackChunkName: "pages/pre-factor/buy" */))
const _a947dbae = () => interopDefault(import('../pages/pre-factor/lir.vue' /* webpackChunkName: "pages/pre-factor/lir" */))
const _867d2e24 = () => interopDefault(import('../pages/pre-factor/sell.vue' /* webpackChunkName: "pages/pre-factor/sell" */))
const _3fb1c286 = () => interopDefault(import('../pages/dashboard/buy/factor.vue' /* webpackChunkName: "pages/dashboard/buy/factor" */))
const _53cfdd91 = () => interopDefault(import('../pages/dashboard/buy/select-address.vue' /* webpackChunkName: "pages/dashboard/buy/select-address" */))
const _6bf95dde = () => interopDefault(import('../pages/dashboard/kyc/accept-rules.vue' /* webpackChunkName: "pages/dashboard/kyc/accept-rules" */))
const _43dbf8e6 = () => interopDefault(import('../pages/dashboard/kyc/upload-card.vue' /* webpackChunkName: "pages/dashboard/kyc/upload-card" */))
const _44dcf04e = () => interopDefault(import('../pages/dashboard/kyc/upload-video.vue' /* webpackChunkName: "pages/dashboard/kyc/upload-video" */))
const _e301f43e = () => interopDefault(import('../pages/dashboard/kyc/user-info.vue' /* webpackChunkName: "pages/dashboard/kyc/user-info" */))
const _77ed5db7 = () => interopDefault(import('../pages/dashboard/sell/factor.vue' /* webpackChunkName: "pages/dashboard/sell/factor" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _28b03768 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _8fa99d56 = () => interopDefault(import('../pages/dashboard/orders/_id.vue' /* webpackChunkName: "pages/dashboard/orders/_id" */))
const _9d03b872 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _e9b98470,
    name: "about-us"
  }, {
    path: "/app",
    component: _a81b68b2,
    name: "app"
  }, {
    path: "/auth",
    component: _2161b8d6,
    name: "auth"
  }, {
    path: "/blog",
    component: _999675e2,
    name: "blog"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/fee",
    component: _bdddbae8,
    name: "fee"
  }, {
    path: "/package",
    component: _7ec5f768,
    name: "package"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/tether-chart",
    component: _b8e80066,
    name: "tether-chart"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _33eac2b0,
    name: "auth-register"
  }, {
    path: "/callback/buy",
    component: _35112aa6,
    name: "callback-buy"
  }, {
    path: "/callback/payment",
    component: _a326cbb4,
    name: "callback-payment"
  }, {
    path: "/callback/sell",
    component: _1c83253c,
    name: "callback-sell"
  }, {
    path: "/dashboard/bank-accounts",
    component: _7622b122,
    name: "dashboard-bank-accounts"
  }, {
    path: "/dashboard/crypto-wallets",
    component: _1c619ad3,
    name: "dashboard-crypto-wallets"
  }, {
    path: "/dashboard/orders",
    component: _ebe5b0a6,
    name: "dashboard-orders"
  }, {
    path: "/dashboard/payment-result",
    component: _40e7a1ee,
    name: "dashboard-payment-result"
  }, {
    path: "/dashboard/profile",
    component: _7e08c7b4,
    name: "dashboard-profile"
  }, {
    path: "/dashboard/referral",
    component: _78c28e22,
    name: "dashboard-referral"
  }, {
    path: "/dashboard/security",
    component: _2cea0045,
    name: "dashboard-security"
  }, {
    path: "/dashboard/wallet",
    component: _3be78fbe,
    name: "dashboard-wallet"
  }, {
    path: "/pre-factor/buy",
    component: _a18ae4cc,
    name: "pre-factor-buy"
  }, {
    path: "/pre-factor/lir",
    component: _a947dbae,
    name: "pre-factor-lir"
  }, {
    path: "/pre-factor/sell",
    component: _867d2e24,
    name: "pre-factor-sell"
  }, {
    path: "/dashboard/buy/factor",
    component: _3fb1c286,
    name: "dashboard-buy-factor"
  }, {
    path: "/dashboard/buy/select-address",
    component: _53cfdd91,
    name: "dashboard-buy-select-address"
  }, {
    path: "/dashboard/kyc/accept-rules",
    component: _6bf95dde,
    name: "dashboard-kyc-accept-rules"
  }, {
    path: "/dashboard/kyc/upload-card",
    component: _43dbf8e6,
    name: "dashboard-kyc-upload-card"
  }, {
    path: "/dashboard/kyc/upload-video",
    component: _44dcf04e,
    name: "dashboard-kyc-upload-video"
  }, {
    path: "/dashboard/kyc/user-info",
    component: _e301f43e,
    name: "dashboard-kyc-user-info"
  }, {
    path: "/dashboard/sell/factor",
    component: _77ed5db7,
    name: "dashboard-sell-factor"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/blog/category/:slug?",
    component: _28b03768,
    name: "blog-category-slug"
  }, {
    path: "/dashboard/orders/:id",
    component: _8fa99d56,
    name: "dashboard-orders-id"
  }, {
    path: "/blog/:slug",
    component: _9d03b872,
    name: "blog-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
